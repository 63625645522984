import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <div className="pt-6 text-sm text-stone-500 flex space-x-2 font-jost px-4 lg:px-0">
        <Link to="/" className="hover:underline">
          Главная
        </Link>
        <div>/</div>
        <Link to="/blog" className="hover:underline">
          Статьи
        </Link>
        <div>/</div>
        <div>{frontmatter.title}</div>
      </div>
      <div className="py-8 grid lg:grid-cols-4 gap-4 px-4 lg:px-0">
        <div className="col-span-3">
          <h1 className="text-3xl font-semibold">{frontmatter.title}</h1>
          <div className=" text-stone-400 text-xs py-3">{frontmatter.date}</div>
          <div
            className="py-4 blog"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id } ) {
      id
      html
      frontmatter {
        date(formatString: "DD.MM.YYYY")
        title
      }
    }
  }
`
